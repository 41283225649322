import Selector from "../scenes/selector/Selector";
import Inicio from "../scenes/inicio/Inicio";
import Configure from "../scenes/configure/Configure";
import Tickets from "../scenes/tickets/Tickets";
import User from "../scenes/user/user";
import Preview from "../scenes/preview/Preview";
import User_2 from "../scenes/user_2/user_2";
import Start from "../scenes/start/Start";
import Send from "../scenes/send/Send";
import Next from "../scenes/next/Next";




export const rutas = [
    { ruta: '/General', component: <Selector /> },
    { ruta: '/inicio', component: <Inicio /> },
    { ruta: '/configure', component: <Configure /> },
    { ruta: '/tickets', component: <Tickets /> },
    { ruta: '/user', component: <User /> },
    { ruta: '/preview', component: <Preview /> },
    { ruta: '/user_2', component: <User_2 /> },
    { ruta: '/Start', component: <Start /> },
    { ruta: '/Send', component: <Send /> },
    { ruta: '/Next', component: <Next /> },
    

    
    
    
    
];
