import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import Instrucciones from "../../assets/Logos/Instrucciones_Cupo.png"
import Instruccion from "../../assets/Logos/instruccion.png"
import LogoTl from "../../assets/Inicio/Logo02.png"
import LogoPue from "../../assets/Inicio/Logo.png"
import Titulo from "../../assets/Inicio/Titulo.png"
import "./Next.scss"
import {useNavigate } from 'react-router-dom';
import axios from 'axios';



const Next = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { message } = location.state || { message: 'Ha ocurrido un error.' };


    useEffect(() => {
        const verificarNumeroTurno = async () => {
            try {
                console.log("Realizando petición al endpoint para verificar el número de turnos...");
                
                const response = await axios.get('https://cemitas.key-city-api.com/api/resolume');
                const numeroTurno = response.data.Numero_Turno;
    
                console.log("Respuesta del endpoint:", response.data);
    
                if (numeroTurno !== 3) {
                    console.log(`El número de turnos (${numeroTurno}) no es 3, redirigiendo a /Start...`);
                    navigate('/Start'); 
                } else {
                    console.log(`El número de turnos (${numeroTurno}) es igual a 3, permaneciendo en la página actual.`);
                }
            } catch (error) {
                console.error("Error al verificar el número de turno:", error);
                navigate('/Start'); 
            }
        };
    
        const interval = setInterval(verificarNumeroTurno, 5000);
    
        return () => {
            console.log("Limpiando intervalo de verificación...");
            clearInterval(interval);
        };
    }, [navigate]);
    

    return (
        <div className="Next">
            
            <div className="body_X">
                <img src={Instruccion} alt="" className="instruccioN"/>
                {/* <img className='titulo_x' src={Titulo} alt="" /> */}
               

            </div>
            <div className="footer_X">
                <img src={LogoTl} className="LogoNL" alt="" />
                <img src={LogoPue} className="LogoNpue" alt="" />

            </div>  
        </div>
    );
};

export default Next;
