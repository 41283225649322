import React, { useEffect, useState } from "react";
import "./PanelTextos.scss";

const PanelTextos = ({ initialText, onTextChange }) => {
  const [text, setText] = useState(initialText || "");

  useEffect(() => {
    setText(initialText); 
  }, [initialText]);

  const handleChange = (e) => {
    const newText = e.target.value;

    // Limitar a 500 caracteres
    if (newText.length <= 500) {
      setText(newText);
      onTextChange(newText); 
    }
  };

  return (
    <div className="panel-textos">
      <textarea
        value={text}
        onChange={handleChange}
        placeholder="Escribe tu carta aquí..."
        className="text-area"
        maxLength={250}
      ></textarea>
      <div style={{color:"white"}} className="character-count">{text.length}/250 caracteres</div> 

    </div>
  );
};

export default PanelTextos;
