import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function Configure() {
    const [iparena, setIparena] = useState('');
    const [server, setServer] = useState(''); 
    const [maxColumn, setMaxColumn] = useState('57'); 
    const [serverPath, setServerPath] = useState(''); 
    const navigate = useNavigate();

    useEffect(() => {
        // Load existing values from localStorage
        const storedIP = localStorage.getItem('iparena');
        const storedServer = localStorage.getItem('ipserver'); 
        const storedColumn = localStorage.getItem('maxcolumnas');
        const storedPath = localStorage.getItem('pathserver');

        if (storedIP) {
            setIparena(storedIP);
        }
        if (storedServer) {
            setServer(storedServer); // Set the state if value exists
        }
        if (storedColumn) {
            setMaxColumn(storedColumn); // Set the state if value exists
        }
        else {
            localStorage.setItem('maxcolumnas', maxColumn);
        }
        if (storedPath) {
            setServerPath(storedPath); // Set the state if value exists
        }
    }, [maxColumn]);

    const handleChangeIP = (event) => {
        const newValue = event.target.value;
        setIparena(newValue);
        localStorage.setItem('iparena', newValue); // Save IP address to localStorage
        // console.log('Guardando iparena:', newValue);
    };

    const handleChangePort = (event) => {
        const newValue = event.target.value;
        setServer(newValue);
        localStorage.setItem('ipserver', newValue); // Save port number to localStorage
        console.log('Guardando ipserver:', newValue);
    };

    const handleChangeColumn = (event) => {
        const newValue = event.target.value;
        setMaxColumn(newValue);
        localStorage.setItem('maxcolumnas', newValue); // Save port number to localStorage
    };

    const handleChangePath = (event) => {
        const newValue = event.target.value;
        setServerPath(newValue);
        localStorage.setItem('pathserver', newValue); // Save port number to localStorage
    };

    const inicio = () => {
        navigate("/");
    }

    return (
        <div>
            <div>
                Dirección IP Arena
                <input
                    type="text"
                    value={iparena}
                    onChange={handleChangeIP}
                />
            </div>
            <div>
                Dirección IP del servidor local
                <input
                    type="text"
                    value={server}
                    onChange={handleChangePort}
                />
            </div>
            <div>
                Maximo de Columnas
                <input
                    type="text"
                    value={maxColumn}
                    onChange={handleChangeColumn}
                />
            </div>
            <div>
                Ruta de los archivos en el servidor
                <input
                    type="text"
                    value={serverPath}
                    onChange={handleChangePath}
                />
            </div>
            <button onClick={inicio}>Ir a inicio</button>
        </div>
    );
}

export default Configure;