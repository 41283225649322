import React from "react";
// import { Button } from "semantic-ui-react";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import "./Inicio.scss"

const Inicio = () => {

    const navigate = useNavigate();

    const entrar_General = () => {
        let ip = localStorage.getItem("iparena");
        if ( !ip ) {
            alert("Por favor configure la dirección IP del servidor Arena");
            navigate('/configure')
            return false;
            
        }
        navigate("/General")
    }

    const entrar_Tickets = () => {
        let ip = localStorage.getItem("iparena");
        if ( !ip ) {
            alert("Por favor configure la dirección IP del servidor Arena");
            navigate('/configure')
            return false;
            
        }
        navigate("/Start")
    }

    return(
        <div className="Inicio" >
            <div className="tittle" >
                Selecciona 
            </div>
            

            <div className="container-button">
                    
                <Button className='All' variant='outlined' startIcon={<GroupsOutlinedIcon style={{ fontSize:'3em' }}/>} onClick={entrar_General}>
                    General
                </Button>

                <Button  className="Turno" variant='outlined' startIcon={<ConfirmationNumberOutlinedIcon style={{ fontSize:'3em' }}/>} onClick={entrar_Tickets}>
                    Turnos
                </Button>
            </div>    
            
        </div>
    )
}

export default Inicio;