import React from "react";

import { tarjetas } from "../../../services/resources";

const PanelTarjeta = ({ onclicElement }) => {

    return (
        <>
            {tarjetas.map((option, index) => (
                <button key={index} onClick={()=>onclicElement(option)}>
                    <img src={option.imagen} alt={option.texto} style={{ maxWidth: "80%", maxHeight: "150px" }} />
                </button>
            ))}
        </>
    );
}

export default PanelTarjeta