import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

import "./Send.scss";

import Enviado from "../../assets/Enviado/Enviado.png";
import LogoTl from "../../assets/Inicio/Logo02.png";
import LogoPue from "../../assets/Inicio/Logo.png";

const Send = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { card, text, turno } = location.state || {};


    useEffect(() => {
        if (turno) {
            Swal.fire({
                title: '¡Éxito!',
                html: `<br>Turno: ${turno}`,
                icon: 'success',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                customClass: {
                    popup: 'swal-popup-custom',
                },
            });
        }
    }, [turno]);

    useEffect(() => {
        const checkTurno = async () => {
            try {
                const response = await axios.get('https://cemitas.key-city-api.com/api/resolume');
                const turnos = response.data.Data || [];
                const turnoEncontrado = turnos.some(t => t.turno === parseInt(turno, 10));

                if (!turnoEncontrado) {
                    Swal.fire({
                        title: 'Turno no válido',
                        text: 'Tu turno ya no es válido',
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                    }).then(() => {
                        localStorage.removeItem("userTurno");
                        navigate("/Start");
                    });
                }
            } catch (error) {
                console.error("Error al verificar el turno:", error);
            }
        };

        const interval = setInterval(() => {
            checkTurno();
        }, 5000);

        return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }, [turno, navigate]);

    return (
        <div className="Send">
            <div className="body_s">
                <img src={Enviado} className="enviadoS" alt="" />
            </div>
            <div className="footer_s">
                <img src={LogoTl} className="logotlEx" alt="" />
                <img src={LogoPue} className="logopuEx" alt="" />
            </div>
        </div>
    );
};

export default Send;
