import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Preview.scss";
import listonMusica from "../../assets/img_aniversario/Inicio/Liston_Musica.png";
import ClearIcon from '@mui/icons-material/Clear';
import Swal from "sweetalert2";


const Preview = () => {
  const [ip, setIp] = useState("");
  const [iparena, setIparena] = useState('');
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    let ipCurrent = localStorage.getItem("ipserver") || "192.168.100.85"; // Valor predeterminado
    let ipArena = localStorage.getItem("iparena") || "192.168.100.85"; // Valor predeterminado
    setIp(ipCurrent);
    setIparena(ipArena);
    // fetchTickets();
    ImagesAndSendToArena();

    const interval = setInterval(() => {
        fetchTickets();
    }, 10000);

    return () => clearInterval(interval);
  }, [setIp]);


  useEffect(() => {
    const storedIp = localStorage.getItem("ipserver");
    const storedIpArena = localStorage.getItem("iparena");
    setIp(storedIp);
    setIparena(storedIpArena);
  }, []);


  useEffect(() => {
    const ticketElements = document.querySelectorAll(".ticket-item-test h3");
    ticketElements.forEach((el) => adjustFontSize(el));
  }, [tickets]);

  const fetchTickets = async () => {
    try {
      const response = await axios.get(
        "https://cemitas.key-city-api.com/api/resolume"
      );
      setTickets(response.data.Data);
    } catch (error) {
      console.error("Error al obtener los tickets:", error);
    }
  };

  
  // useEffect(() => {
  //   Descargar_img();
  // }, []);

  const eliminarTurno = async (id) => {
    try {
      const response = await axios.delete("https://cemitas.key-city-api.com/api/resolume_delete", {
        data: { id },
        headers: { "Content-Type": "application/json" },
      });
      console.log(response.data.message);
      alert("Turno eliminado exitosamente.");
      fetchTickets(); 
    } catch (error) {
      console.error("Error al eliminar el turno:", error);
      alert("No se pudo eliminar el turno.");
    }
  };

  const ImagesAndSendToArena = async () => {
    try {
      const url = "http://" + ip + "/Didactico_Carta/images/";
        console.log("Buscando imágenes desde:", url);

        const response = await axios.get(url, { responseType: 'text' });
        const html = response.data;

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const links = Array.from(doc.querySelectorAll("a"))
            .map((link) => link.getAttribute("href"))
            .filter((href) => href && href.match(/\.(jpg|jpeg|png|gif|png)$/i));

        if (links.length > 0) {
            console.log("Imágenes encontradas:", links);

            console.log("Iterando sobre las imágenes...");
            for (const link of links) {
                // Por ejemplo, si "1.png" debe ser el clip 1:
                const clipNumberFromName = parseInt(link.split('.')[0], 10);

                const photo = {
                    filename: `C:/xampp/htdocs/Didactico_Carta/images/${link}`,
                    layer: 3,
                    clip: clipNumberFromName
                };

                console.log(`Procesando imagen: ${photo.filename}`);
                await openPhoto(photo, link); 
            }
            console.log("Iteración completada.");
        } else {
            console.log("No se encontraron imágenes en el directorio.");
        }
    } catch (error) {
        console.error("Error en la búsqueda de imágenes:", error);
    }
};

const openPhoto = async (photo, link) => {
  try {
      const ipArena = localStorage.getItem("iparena");
      const baseURL = `http://${ipArena}:8080/api/v1/composition/layers/${photo.layer}/clips/${photo.clip}/open`;

      const localPath = `file:///C:/xampp/htdocs/Didactico_Carta/images/${link}`;
      
      console.log(`Abriendo fuente en el clip (POST open): ${baseURL}`);
      console.log(`Enviando ruta: ${localPath}`);

      const response = await axios.post(
          baseURL, 
          localPath, 
          {
              headers: {
                  'Content-Type': 'text/plain'
              }
          }
      );

      console.log("Respuesta del servidor:", response.data);
      console.log(`Clip abierto con éxito con el archivo: ${localPath}`);

  } catch (error) {
      console.error("Error abriendo la imagen en Resolume Arena:", error);
      if (error.code === "ERR_NETWORK") {
          alert("No se puede encontrar el servidor de Arena");
      }
  }
};

const sendPhotoConnect = async (photo) => {
  let url = "http://" + iparena + ":8080/api/v1/composition/layers/" + photo.layer + "/clips/" + photo.clip + "/connect";
  console.log("Enviando foto a Arena (connect):", url);
  sendToArena(url);
};




  
const Descargar_img = async () => {
  try {
    setLoading(true);
    const url = `http://${ip}/Didactico_Carta/Descargar_Imagen.php`;
    console.log(`Downloading images from: ${url}`);
    const response = await axios.get(url);
    console.log("Downloaded images response:", response.data);

    alert("Se han descargado las imágenes");

    
    await ImagesAndSendToArena();
  } catch (error) {
    console.error("Error from axios:", error);
    setError("Error al cargar las fotos.");
    setLoading(false);
  }
};
  



  const adjustFontSize = (element) => {
    const maxHeight = 100; // Altura máxima permitida (ajusta según el diseño)
    while (element.scrollHeight > maxHeight) {
      const currentFontSize = parseFloat(
        window.getComputedStyle(element).fontSize
      );
      element.style.fontSize = `${currentFontSize - 1}px`;
    }
    element.classList.add("scrollable");
  };


  

  const sendlienzo = async (clip) => {
    let url ="http://" + iparena + ":8080/api/v1/composition/layers/2/clips/" + clip + "/connect";
    console.log(url)
    sendToArena(url);
  };

  const handleSend = (ticket) => {
    sendlienzo(ticket.lienzo);
    const photo = {
      layer: 3,
      clip: ticket.turno
    };
    sendPhotoConnect(photo);
  };

  const sendToArena = async (url) => {
    try {
      const responseReset = await axios.post(url, "false");
      const response = await axios.post(url, "true");
      console.log("Response:", response.data);
      console.log("Response:", responseReset.data);
    } catch (error) {
      console.error("Error from axios:", error);
    }
  };

  const clearPhoto = async (layer, clip) => {
    try {
        const ipArena = localStorage.getItem("iparena");
        const url = `http://${ipArena}:8080/api/v1/composition/layers/${layer}/clips/${clip}/clear`;
        console.log("Limpiando imagen en Resolume con:", url);

        const response = await axios.post(url);
        console.log("Respuesta de clear:", response.data);
        console.log(`Clip ${clip} en capa ${layer} limpiado con éxito.`);
    } catch (error) {
        console.error("Error limpiando la imagen en Resolume Arena:", error);
    }
  };

  const getImagesList = async () => {
    const url = `http://${ip}/Didactico_Carta/images/`;
    console.log("Buscando imágenes para limpiar desde:", url);

    const response = await axios.get(url, { responseType: 'text' });
    const html = response.data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = Array.from(doc.querySelectorAll("a"))
        .map((link) => link.getAttribute("href"))
        .filter((href) => href && href.match(/\.(jpg|jpeg|png|gif|png)$/i));

    return links;
  };





  const Reseturnimg = async () => {
    try {
        // Primero obtenemos la lista de imágenes actualmente en la carpeta
        const links = await getImagesList();

        // Si hay imágenes, iteramos sobre ellas para limpiarlas
        if (links.length > 0) {
            console.log("Imágenes a limpiar en Resolume:", links);

            for (const link of links) {
                // Ejemplo: "1.png" => clipNumber = 1
                const clipNumberFromName = parseInt(link.split('.')[0], 10);
                const layer = 3; // Ajusta si tu capa es otra

                await clearPhoto(layer, clipNumberFromName);
            }
            console.log("Limpieza en Resolume completada.");
        } else {
            console.log("No se encontraron imágenes para limpiar.");
        }

        // Después de limpiar los clips en Resolume, elimina las imágenes localmente y resetea turnos
        await Eliminarimg();
        await ResetTurnos();
    } catch (error) {
        console.error("Error durante la ejecución de las funciones combinadas:", error);
    }
  };


  const ResetTurnos = async () => {
    try {
      const response = await axios.post(
        "https://cemitas.key-city-api.com/api/resolume_turnos",
        {}, 
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Turnos reiniciados:", response.data);
      alert("Turnos reiniciados exitosamente.");
    } catch (error) {
      console.error("Error al reiniciar los turnos:", error);
      alert("Ocurrió un error al reiniciar los turnos.");
    }
  };


  const Eliminarimg = async () => {

    try {
        setLoading(true);
        let url = "http://" + ip + "/Didactico_Carta/Eliminar_Imagen.php"; 
        console.log('Ruta del Servidor' + url);
        const response = await axios.post(url);
        console.log(response);
    } catch (error) {
        console.error('Error from axios:', error);
        setError("Error al cargar las fotos.");
        setLoading(false);
    }
  };

  return (
    <div className="General-test">
        <div className="buttons-pre">
            <button className="button-D" onClick={Descargar_img} >Descargar</button>
            <button className="button-R" onClick={Reseturnimg} >Reiniciar turnos</button>
        </div>
        <div className="contenedor-test">
            <div className="body-test" style={{ fontSize: "1.5em" }}>
            {tickets.map((ticket) => (
                <button
                    key={ticket.id}
                    className="ticket-item-test"
                    onClick={() => handleSend(ticket)}
                    >
                    <ClearIcon
                    className="clear-icon"
                    onClick={(e) => {
                    e.stopPropagation();
                    eliminarTurno(ticket.id);
                    }}
                    />
                    <h3>{ticket.texto}</h3>
                    <h6>{`Nombre: ${ticket.nombre_completo}`}</h6>
                    <h6>Turno: {ticket.turno}</h6>
                </button>
            ))}
            </div>
      </div>
    </div>
  );
};

export default Preview;
