import React, {useEffect, useState} from "react";
import axios from "axios";
import ToolBar from "./components/ToolBar";
// import PanelMusica from "./components/PanelMusica";
// import PanelArcos from "./components/PanelArcos";
// import PanelLienzo from "./components/PanelLienzo";
// import PanelTextos from "./components/PanelTextos";
// import PanelFotos from "./components/PanelFotos";
import { Button } from "@mui/material";

import Footer from "./components/Footer";
    
import "./user_2.scss"
import titulo from "../../assets/img_aniversario/Inicio/p_letrero.png";
import carta from "../../assets/img_14Febrero/IC_10.png";
import fondoSelect from "../../assets/img_aniversario/Inicio/Seleccionado.png"

import listonMusica from "../../assets/img_aniversario/Inicio/Liston_Musica.png"
import listonArcos from "../../assets/img_aniversario/Inicio/Liston_Arcos.png"
import listonLienzo from "../../assets/img_aniversario/Inicio/Liston_Lienzo.png"
// import listonMensaje from "../../assets/img_Dia_delas_Madres/Inicio/Liston_Mensajue.png"
import listonFoto from "../../assets/img_aniversario/Inicio/Liston_foto.png"
import Swal from "sweetalert2";

const lastClip = "64";

const User_2 = () => {

    const [ panel, setPanel ] = useState("musica");
    const [ musica, setMusica ] = useState(null);
    const [ arco, setArco ] = useState(null);
    const [ lienzo, setLienzo ] = useState(null);
    const [ texto, setTexto ] = useState(null);
    const [ foto, setFoto ] = useState(null);
    const [ ip, setIp ] = useState("192.168.3.84");
    const [name, setName] = useState("");
    const [turno, setTurno] = useState(localStorage.getItem("userTurno"));


    const [ liston, setliston ] = useState(listonMusica);
    const [ showModal, setshowModal ] = useState(false);

    useEffect( () => {
        let ipCurrent = localStorage.getItem("iparena");
        setIp(ipCurrent);
        fetchTurno()
    }, [setIp]);

    const fetchTurno = async () => {
        try {
            const response = await axios.get('https://cemitas.key-city-api.com/api/resolume');
            const ticket = response.data.data[0];
            setTurno(ticket.turno);
        } catch (error) {
            console.error("Error al obtener el turno:", error);
        }
    };

    const selectPanel = (panel) => {
        setPanel(panel);
        
        switch (panel) {
            case "musica":
                setliston(listonMusica)
                break;

            case "arco":
                setliston(listonArcos)
                break;

            case "lienzo":
                setliston(listonLienzo)
                break;

            // case "texto":
            //     setliston(listonMensaje)
            //     break;
            default:
                setliston(listonFoto)
                break;
        }
    }

    const selectElement = async (elemento) => {
        console.log("enviando a arena", elemento);
        let url = "http://" + ip + ":8080/api/v1/composition/layers/"+ elemento.layer + "/clips/" + elemento.clip + "/connect";
        if ( panel === 'musica' ) {
            setMusica(elemento);
        }
        if ( panel === 'arco' ) {
            setArco(elemento);
        }
        if ( panel === 'lienzo' ) {
            setLienzo(elemento);
        }
        // if ( panel === 'texto' ) {
        //     setTexto(elemento);
        // }
        if ( panel === 'foto' ) {
            setFoto(elemento);
        }
        await sendToArena(url);
    }

    const reset = () => {
        setMusica(null);
        setArco(null);
        setLienzo(null);
        setTexto(null);
        let lastclip = localStorage.getItem("maxcolumnas");
        let url = "http://" + ip + ":8080/api/v1/composition/layers/1/clips/" + lastclip + "/connect";
        //sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/2/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/3/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/4/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/5/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/6/clips/" + lastclip + "/connect";
        sendToArena(url);

        selectPanel("musica");
        
    }

    const sendToArena = async ( url ) => {
        try {
            const responseReset = await axios.post(url, "false");
            const response = await axios.post(url, "true");
            console.log('Response:', response.data);
            console.log('Response:', responseReset.data);
        } catch (error) {
            console.error('Error from axios:', error); 
            /*if ( error.code === "ERR_NETWORK" ) {
                alert("No se puede encontrar el servidor de Arena");
            }*/
        }
    }

    const openModal = () => {
        setName(""); 
        setshowModal(true);
       
    
    }

    const closeModal = () => {
        setshowModal(false);
    }

    const handleSend = async() => {
        if (musica && arco && lienzo && name.trim() !== "") {
            const data = {
                nombre:name.trim(),
                arco: arco.clip,
                lienzo: lienzo.clip,
                cancion: musica.clip
            };

            try{
                const response = await axios.post('https://cemitas.key-city-api.com/api/resolume',data,{
                    headers:{
                        'Content-Type': 'application/json'
                    }
                });
                
                // closeModal()
                const newTicket = response.data.resolume;
                if (newTicket && newTicket.turno !== undefined) {
                    setTurno(newTicket.turno);
                    localStorage.setItem("userTurno",newTicket.turno);
                   
                    const successMessage = `<br>Turno: ${newTicket.turno}`;
                    
                    Swal.fire({
                        title: '¡Éxito!',
                        html: successMessage,  
                        icon: 'success',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false
                    }); 
                    
                }else {
                    console.error('Turno no encontrado en la respuesta:', newTicket);
                    Swal.fire('Error', 'No se encontró el turno en la respuesta.', 'error');
                    
                }
                closeModal()
                       
            }catch(error){
                console.error('Error al enviar los datos',error);
            }
        } else {
            Swal.fire('Error', 'Asegúrate de haber seleccionado una canción, un arco, un lienzo y de haber llenado el campo de nombre.', 'error');
            closeModal()
        }
    }


    useEffect(() => {
        const storedTurno = localStorage.getItem("userTurno");
        if (storedTurno) {
            Swal.fire({
                title: '¡Éxito!',
                html: `Turno: ${storedTurno}`,
                icon: 'success',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false
            });
        }
    }, []);


    return (
        <div className="User_2">
            <div className="head">
                <div className="head-element head-seleccion">
                    <img src={fondoSelect} alt="" className='cuadro-seleccion' /*style={{position:"absolute", width:"21%", left:"5%", top:"13vh"}}*//>
                    <div className="image-container">
                        <img src={musica?.imagen} alt=""  />
                        <img src={arco?.imagen} alt=""  className="img-arco-u"/>
                        <img src={lienzo?.imagen} alt="" className="img-lienzo-u"/>
                        {/* <img src={texto?.imagen} alt="" className="image-texto"  /> */}
                    </div>
                </div>
                <div className="head-element">
                    <img src={titulo} alt="Diseña tu carta" className="img-titulo" />
                </div>
                <div className="head-element head-logo-tl">
                    {/* <button onClick={openModal} className="open-Modal"> */}
                       <img src={carta} alt="Nueva carta" onClick={openModal}  className="img-titlo-tl" />
                    {/* </button> */}
                </div>

                <img src={liston} width={"25%"} alt="FotoListon" className="img-liston" style={{position:"absolute", right:0}}/>

            </div>
            <div className="icons">
                <div className="icon-space"></div>
                <ToolBar switchPanel={selectPanel} />
                <div className="icon-space"></div>
            </div>
            <div className="body">
                {/* { panel==="musica"?  <PanelMusica onclicElement={selectElement} /> : null }
                { panel==="arco"?  <PanelArcos onclicElement={selectElement} /> : null }
                { panel==="lienzo"?  <PanelLienzo onclicElement={selectElement} /> : null } */}
                {/* { panel==="texto"?  <PanelTextos onclicElement={selectElement} /> : null } */}
                {/* { panel==="foto"?  <PanelFotos onclicElement={selectElement} /> : null } */}
            </div>
            <div className="footer" />
            {showModal && (
                <div className="modal">

                    <div className="modal-tittle">
                        <Button onClick={closeModal} variant="outlined" color="error" size="small">
                            X
                        </Button>
                    </div>
                    
                    <div className="modal-body">
                        <div className="resultado-container">
                            <img src={fondoSelect} alt="" className='resultado'/>
                            <img src={musica?.imagen} alt="" className="img-musica-u"  />
                            <img src={arco?.imagen} alt=""  className="img-arco-u"/>
                            <img src={lienzo?.imagen} alt="" className="img-lienzo-u"/>
                        </div>
                        <div className="Name-container">
                            <label htmlFor="name"> Ingresa tu nombre completo : </label>
                            <input type="text" id="name" className="name-input" onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="button-container">
                            <Button variant="contained" color="primary" onClick={handleSend} className="button-send">
                                Enviar
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default User_2;
