import React, {useEffect, useState} from "react";
import axios from "axios";
import PanelMusica from "./components/PanelMusica";


    
import "./Tickets.scss"

import titulo from "../../assets/img_aniversario/Inicio/p_letrero.png";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CameraAltIcon from '@mui/icons-material/CameraAlt';


import listonMusica from "../../assets/img_aniversario/Inicio/Liston_Musica.png"
import listonArcos from "../../assets/img_aniversario/Inicio/Liston_Arcos.png"
import listonLienzo from "../../assets/img_aniversario/Inicio/Liston_Lienzo.png"
// import listonMensaje from "../../assets/img_Dia_delas_Madres/Inicio/Liston_Mensajue.png"
import listonFoto from "../../assets/img_aniversario/Inicio/Liston_foto.png"

// const lastClip = "64";

const Tickets = () => {

    // const [ panel, setPanel ] = useState("musica");
    const [ panel, setPanel ] = useState("lienzo");
    const [ panel2, setPanel2 ] = useState("musica");
    const [ musica, setMusica ] = useState(null);
    const [ arco, setArco ] = useState(null);
    const [ lienzo, setLienzo ] = useState(null);
    const [ texto, setTexto ] = useState(null);
    const [ foto, setFoto ] = useState(null);
    const [ ip, setIp ] = useState("192.168.3.84");
    const [tickets, setTickets] = useState([]);

    const [ liston, setliston ] = useState(listonMusica)

    
    useEffect( () => {
        let ipCurrent = localStorage.getItem("iparena");
        setIp(ipCurrent);
        fetchTickets();

        const interval = setInterval(() => {
            fetchTickets();
        }, 1000); 

        return () => clearInterval(interval);

    }, [setIp]);

    const fetchTickets = async () => {
        try {
            const response = await axios.get('https://cemitas.key-city-api.com/api/resolume');
            setTickets(response.data.data);
        } catch (error) {
            console.error("Error al obtener los tickets:", error);
        }
    };



    const selectPanel = (panel) => {
        setPanel(panel);
        
        switch (panel) {
            case "musica":
                setliston(listonMusica)
                break;

            case "arco":
                setliston(listonArcos)
                break;

            case "lienzo":
                setliston(listonLienzo)
                break;

            // case "texto":
            //     setliston(listonMensaje)
            //     break;
            default:
                setliston(listonFoto)
                break;
        }
    }

    

    const selectElement = async (elemento) => {
        console.log("enviando a arena", elemento);
        let url = "http://" + ip + ":8080/api/v1/composition/layers/"+ elemento.layer + "/clips/" + elemento.clip + "/connect";
        if ( panel === 'musica' ) {
            setMusica(elemento);
        }
        if ( panel === 'arco' ) {
            setArco(elemento);
        }
        if ( panel === 'lienzo' ) {
            setLienzo(elemento);
        }

        if ( panel === 'foto' ) {
            setFoto(elemento);
        }
        await sendToArena(url);
    }

    const sendarco = async(clip) => {
        let url = "http://" + ip + ":8080/api/v1/composition/layers/5/clips/" + clip + "/connect";
        sendToArena(url)
    }

    const sendmusic = async(clip) => {
        let url = "http://" + ip + ":8080/api/v1/composition/layers/6/clips/" + clip + "/connect";
        sendToArena(url)
    }   

    const sendlienzo = async(clip) => {
        let url = "http://" + ip + ":8080/api/v1/composition/layers/3/clips/" + clip + "/connect";
        sendToArena(url)
    }


    const handleSend = (ticket) => {
        sendarco(ticket.arco);
        sendmusic(ticket.cancion);
        sendlienzo(ticket.lienzo);
    };
    


    // const reset = () => {
    //     setMusica(null);
    //     setArco(null);
    //     setLienzo(null);
    //     setTexto(null);
    //     let lastclip = localStorage.getItem("maxcolumnas");
    //     let url = "http://" + ip + ":8080/api/v1/composition/layers/1/clips/" + lastclip + "/connect";
    //     //sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/2/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/3/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/4/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/5/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/6/clips/" + lastclip + "/connect";
    //     sendToArena(url);

    //     selectPanel("musica");
        
    // }

    const sendToArena = async ( url ) => {
        try {
            const responseReset = await axios.post(url, "false");
            const response = await axios.post(url, "true");
            console.log('Response:', response.data);
            console.log('Response:', responseReset.data);
        } catch (error) {
            console.error('Error from axios:', error); 
            /*if ( error.code === "ERR_NETWORK" ) {
                alert("No se puede encontrar el servidor de Arena");
            }*/
        }
    }

    return (
        <div className="General">
            <div className="head-tickets">
                
                <div className="head-element-tickets">
                    <img src={titulo} alt="Diseña tu carta" className="img-titulo-tickets" />
                </div>
                

                {/* <img src={liston} width={"25%"} alt="FotoListon" className="img-liston" style={{position:"absolute", right:0}}/> */}

            </div>
            <div className="icons-tickets">
                <div className="icon-space"></div>
                {/* <ToolBar switchPanel={selectPanel} /> */}
                <div className="icon-space"></div>
            </div>
            <div className="Caja_texto">
                <div className="Turnos">
                <ConfirmationNumberIcon style={{fontSize:'1.5em'}}/>
                    {/* Turnos  */}
                </div>     
                <div className="Fotos">
                    <CameraAltIcon style={{fontSize:'1.5em'}}/>
                </div>
            </div>

            <div className="contenedor-tp">
                <div className="body-tickets" style={{fontSize:"1.5em"}}> 
                    {tickets.map(ticket => (
                        <button key={ticket.id} className="ticket-item" onClick={()=>handleSend(ticket)}> 
                                <h3>{ticket.nombre_completo}</h3>
                                <p>Turno: {ticket.turno}</p>  
                        </button>
                    ))}
                </div>
                <div className="body-photos">
                    {/* { panel==="musica"?  <PanelMusica onclicElement={selectElement} /> : null }
                    { panel==="arco"?  <PanelArcos onclicElement={selectElement} /> : null }
                    { panel==="lienzo"?  <PanelLienzo onclicElement={selectElement} /> : null }
                    {/* { panel==="texto"?  <PanelTextos onclicElement={selectElement} /> : null } */}
                    
                </div>
            </div>  

            <div className="footer" />
        </div>
    );
}

export default Tickets;
