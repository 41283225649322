import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import "./Start.scss"

import Titulo from "../../assets/Inicio/Titulo.png"
import LogoTl from "../../assets/Inicio/Logo02.png"
import LogoPue from "../../assets/Inicio/Logo.png"
import nav from "../../assets/Inicio/Logo_Villa.png"




const Start = () => {

    const navigate = useNavigate();
   const enter = () =>{
         navigate("/user")
   }


    return (
        <div className="Start">
            
            <div className="body_u">
                <img className="img_t" src={Titulo} alt=""/>
                <div className="boton-E" onClick={enter}>
                    <button className="boton-Empezar">Empezar</button>
                </div>
            </div>
            <div className="footer_u">
                <img className="logoTl" src={LogoTl} alt="" />
                <img className="logonav" src={nav} alt="" />
                <img className="logoPue" src={LogoPue} alt="" />
                
            </div>  
        </div>
    );
}

export default Start;
