import React, {useEffect, useState} from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

import ToolBar from "./components/ToolBar";
import PanelTarjeta from "./components/PanelTarjetas";
import PanelTextos from "./components/PanelTextos";
import { Button } from "@mui/material";import Inicio from "../inicio/Inicio";
import LogoTl from "../../assets/Inicio/Logo02.png"
import LogoPue from "../../assets/Inicio/Logo.png"
import eligeT from "../../assets/Seleccion/Letrero_Carta.png"
import contenedor from "../../assets/Seleccion/Seleccion.png"
import cartab from "../../assets/Logos/Logo1.png"
import cartaS from "../../assets/Logos/Logo1_Seleccionado.png"
import escribirb from "../../assets/Logos/Logo2.png"
import escribirS from "../../assets/Logos/Logo2_Seleccionado.png"



import carta from "../../assets/Logos/carta.png";


    
import "./user.scss"
import fondoSelect from "../../assets/img_aniversario/Inicio/Seleccionado.png"

import listonMusica from "../../assets/img_aniversario/Inicio/Liston_Musica.png"
import listonFoto from "../../assets/img_aniversario/Inicio/Liston_foto.png"
import Swal from "sweetalert2";

const lastClip = "64";

const User = () => {
    const navigate = useNavigate();
    const [ panel, setPanel ] = useState("tarjeta");
    const [ musica, setMusica ] = useState(null);
    const [ arco, setArco ] = useState(null);
    const [ lienzo, setLienzo ] = useState(null);
    const [ texto, setTexto ] = useState(null);
    const [ foto, setFoto ] = useState(null);
    const [ ip, setIp ] = useState("192.168.3.84");
    const [name, setName] = useState("");
    const [turno, setTurno] = useState(localStorage.getItem("userTurno"));
    const [selectedImage, setSelectedImage] = useState("cartab"); 
    const [selectedCard, setSelectedCard] = useState(null); 
    const [cardText, setCardText] = useState(""); 
    const [ showModal, setshowModal ] = useState(false);
    const [turnoSimulado, setTurnoSimulado] = useState(0); 
   



    // useEffect( () => {
    //     let ipCurrent = localStorage.getItem("iparena");
    //     setIp(ipCurrent);
    //     fetchTurno()
    // }, [setIp]);

    
    

    useEffect(() => {
        const storedTurno = localStorage.getItem("userTurno");
        if (storedTurno) {
            navigate("/Send", {
                state: { card: selectedCard, text: cardText, turno: storedTurno },
            });
        }
    }, [navigate, selectedCard, cardText]);

    const fetchTurno = async () => {
        try {
            const response = await axios.get('https://cemitas.key-city-api.com/api/resolume');
            const ticket = response.data.data[0];
            setTurno(ticket.turno);
        } catch (error) {
            console.error("Error al obtener el turno:", error);
        }
    };


    // useEffect(() => {
    //     const verificarNumeroTurno = async () => {
    //         try {
    //             const response = await axios.get("https://cemitas.key-city-api.com/api/resolume");
    //             const numeroTurno = response.data.Numero_Turno;

                
    //             if (numeroTurno === 3) {
    //                 navigate("/Next");
    //             }
    //         } catch (error) {
    //             console.error("Error al verificar el número de turno:", error);
    //         }


    // };

        
    //     const interval = setInterval(verificarNumeroTurno, 15000);

    //     return () => clearInterval(interval);
    // }, [navigate]);

    

    const selectPanel = (panel) => {
        setPanel(panel);
        
        switch (panel) {
            case "tarjeta":
                // setliston(listonMusica)
                break;

            // case "texto":
            //     setliston(listonMensaje)
            //     break;
            default:
                // setliston(listonFoto)
                break;
        }
    }

    // const selectElement = async (elemento) => {
    //     console.log("enviando a arena", elemento);
    //     let url = "http://" + ip + ":8080/api/v1/composition/layers/"+ elemento.layer + "/clips/" + elemento.clip + "/connect";
    //     if ( panel === 'musica' ) {
    //         setMusica(elemento);
    //     }
    //     if ( panel === 'arco' ) {
    //         setArco(elemento);
    //     }
    //     if ( panel === 'lienzo' ) {
    //         setLienzo(elemento);
    //     }
    //     // if ( panel === 'texto' ) {
    //     //     setTexto(elemento);
    //     // }
    //     if ( panel === 'foto' ) {
    //         setFoto(elemento);
    //     }
    //     await sendToArena(url);
    // }

    // const reset = () => {
    //     setMusica(null);
    //     setArco(null);
    //     setLienzo(null);
    //     setTexto(null);
    //     let lastclip = localStorage.getItem("maxcolumnas");
    //     let url = "http://" + ip + ":8080/api/v1/composition/layers/1/clips/" + lastclip + "/connect";
    //     //sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/2/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/3/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/4/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/5/clips/" + lastclip + "/connect";
    //     sendToArena(url);
    //     url = "http://" + ip + ":8080/api/v1/composition/layers/6/clips/" + lastclip + "/connect";
    //     sendToArena(url);

    //     selectPanel("musica");
        
    // }

    const sendToArena = async ( url ) => {
        try {
            const responseReset = await axios.post(url, "false");
            const response = await axios.post(url, "true");
            console.log('Response:', response.data);
            console.log('Response:', responseReset.data);
        } catch (error) {
            console.error('Error from axios:', error); 
        }
    }

    const ImageClick = (image) => {
        setSelectedImage(image); 
    };
    
    
    const CardSelection = (option) => {
        setSelectedCard({ imagen: option.imagen, clip: option.clip }); 
    };
    

    
    

    const openModal = () => {
        setName(""); 
        setshowModal(true);
       
    
    }

    const TextChange = (text) => {
        setCardText(text); 
      };

    const closeModal = () => {
        setshowModal(false);
    }

    
    const handleSend = async () => {
        if (selectedCard && cardText.trim() !== "" && name.trim() !== "") {
            const data = {
                nombre: name.trim(),
                texto: cardText.trim(),
                lienzo: selectedCard.clip, 
                arco: 0, 
                cancion: 0, 
            };
    
            try {
                const response = await axios.post('https://cemitas.key-city-api.com/api/resolume', data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                const newTicket = response.data.resolume;
                if (newTicket && typeof newTicket.turno !== 'undefined') {
                    setTurno(newTicket.turno);
                    localStorage.setItem('userTurno', newTicket.turno);
    
                    
                    navigate("/Send", {
                        state: { card: selectedCard, text: cardText, turno: newTicket.turno },
                    });
                } else {
                    console.error('El servidor no devolvió el campo "turno".', response.data);
                    Swal.fire('Error', 'No se encontró el turno en la respuesta.', 'error');
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.message || 'Se alcanzó el límite de turnos.';
                    Swal.fire({
                        title: 'Turnos agotados',
                        text: errorMessage,
                        icon: 'warning',
                        confirmButtonText: 'Aceptar',
                    }).then(() => {
                        navigate("/Next", { state: { message: errorMessage } });
                    });
                } else {
                    console.error('Error al enviar los datos:', error.response ? error.response.data : error.message);
                    Swal.fire('Error', 'Ocurrió un error al intentar enviar la información al servidor.', 'error');
                }
            }
        } else {
            Swal.fire('Error', 'Asegúrate de haber seleccionado una carta, escrito un texto y llenado el campo de nombre.', 'error');
        }
    };
    
    
    
    
    
    


    useEffect(() => {
        const verificarTurno = async () => {
            const storedTurno = localStorage.getItem("userTurno");
            if (!storedTurno) return; // Si no hay turno en localStorage, no hacer nada

            try {
                const response = await axios.get('https://cemitas.key-city-api.com/api/resolume');
                const turnos = response.data.Data || [];
                const turnoValido = turnos.some((t) => t.turno === parseInt(storedTurno, 10));

                if (turnoValido) {
                    navigate("/send", { state: { turno: storedTurno } });
                } else {
                    localStorage.removeItem("userTurno");
                    setTurno(null);
                }
            } catch (error) {
                console.error("Error al verificar el turno:", error);
            }
        };

        verificarTurno();
    }, [navigate]);
    


    return (
        <div className="User">
            <div className="head_user">
                <img src={LogoTl} className="logotl"  alt="" />
                <img src={LogoPue} className="logoPue"  alt="" />
            </div>
            <div className="sub-header">
                <img
                    className="tarjeta"
                    src={eligeT} 
                    alt=""
                />
            </div>
           <div className="container_n">
                <img className="contenedor_img" src={contenedor} alt="" />
                {selectedCard && (
                   <>
                   <img
                     className="carta_seleccionada"
                     src={selectedCard.imagen}
                     alt="Carta seleccionada"
                   />
                   <div className="texto_sobre_carta">{cardText}</div> 
                 </>
                )} 
           </div>
           <div className="icons_s">
                <img className="icon-cartab" src={selectedImage === "cartab" ? cartaS : cartab }  onClick={() => ImageClick("cartab")} alt="" />
                <img className="icon-escribir"  src={selectedImage === "escribirb" ? escribirS : escribirb}  onClick={() => ImageClick("escribirb")} alt="" />
           </div>
            
            <div className="carta_env">
                <img className="send" onClick={openModal} src={carta} alt="" />
            </div>
            
            <div className="body">
                {selectedImage === "cartab" && (
                    <PanelTarjeta onclicElement={CardSelection} />
                )}

                {selectedImage === "escribirb" && (
                    <PanelTextos  initialText={cardText} onTextChange={TextChange} />
                )}
            </div>

            
            <div className="footer" />
            {showModal && (
                <div className="modal_u">

                    <div className="modal-tittle">
                        <Button onClick={closeModal} variant="outlined" color="error" size="small">
                            X
                        </Button>
                    </div>
                    
                    <div className="modal-body">
                        <div className="resultado-container">
                            <img src={contenedor} alt="" className="resultado" />
                                {selectedCard && (
                                <>
                                    <img  
                                    src={selectedCard.imagen}
                                    alt="Carta seleccionada"
                                    className="modal-carta-seleccionada"
                                    />
                                    <div className="modal-texto-sobre-carta">{cardText}</div>
                                </>
                                )}
                        </div>
                        <div className="Name-container">
                            <label htmlFor="name"> Ingresa tu nombre completo : </label>
                            <input type="text" id="name" className="name-input" onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="button-container">
                            <Button variant="contained" color="primary" onClick={handleSend} className="button-send">
                                Enviar
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default User;
