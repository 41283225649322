import React, {useEffect, useState} from "react";
import axios from "axios";
import ToolBar from "./components/ToolBar";
import PanelMusica from "./components/PanelMusica";


import Footer from "./components/Footer";
    
import "./Selector.scss"
import titulo from "../../assets/img_aniversario/Inicio/p_letrero.png";
import carta from "../../assets/img_14Febrero/IC_10.png";
import fondoSelect from "../../assets/img_aniversario/Inicio/Seleccionado.png"

import listonMusica from "../../assets/img_aniversario/Inicio/Liston_Musica.png"
import listonArcos from "../../assets/img_aniversario/Inicio/Liston_Arcos.png"
import listonLienzo from "../../assets/img_aniversario/Inicio/Liston_Lienzo.png"
// import listonMensaje from "../../assets/img_Dia_delas_Madres/Inicio/Liston_Mensajue.png"
import listonFoto from "../../assets/img_aniversario/Inicio/Liston_foto.png"

const lastClip = "64";

const Selector = () => {

    const [ panel, setPanel ] = useState("musica");
    const [ musica, setMusica ] = useState(null);
    const [ arco, setArco ] = useState(null);
    const [ lienzo, setLienzo ] = useState(null);
    const [ texto, setTexto ] = useState(null);
    const [ foto, setFoto ] = useState(null);
    const [ ip, setIp ] = useState("192.168.3.84");

    const [ liston, setliston ] = useState(listonMusica)

    useEffect( () => {
        let ipCurrent = localStorage.getItem("iparena");
        setIp(ipCurrent);
    }, [setIp]);

    const selectPanel = (panel) => {
        setPanel(panel);
        
        switch (panel) {
            case "musica":
                setliston(listonMusica)
                break;

            case "arco":
                setliston(listonArcos)
                break;

            case "lienzo":
                setliston(listonLienzo)
                break;

            // case "texto":
            //     setliston(listonMensaje)
            //     break;
            default:
                setliston(listonFoto)
                break;
        }
    }

    const selectElement = async (elemento) => {
        console.log("enviando a arena", elemento);
        let url = "http://" + ip + ":8080/api/v1/composition/layers/"+ elemento.layer + "/clips/" + elemento.clip + "/connect";
        if ( panel === 'musica' ) {
            setMusica(elemento);
        }
        if ( panel === 'arco' ) {
            setArco(elemento);
        }
        if ( panel === 'lienzo' ) {
            setLienzo(elemento);
        }
        // if ( panel === 'texto' ) {
        //     setTexto(elemento);
        // }
        if ( panel === 'foto' ) {
            setFoto(elemento);
        }
        await sendToArena(url);
    }

    const reset = () => {
        setMusica(null);
        setArco(null);
        setLienzo(null);
        setTexto(null);
        let lastclip = localStorage.getItem("maxcolumnas");
        let url = "http://" + ip + ":8080/api/v1/composition/layers/1/clips/" + lastclip + "/connect";
        //sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/2/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/3/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/4/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/5/clips/" + lastclip + "/connect";
        sendToArena(url);
        url = "http://" + ip + ":8080/api/v1/composition/layers/6/clips/" + lastclip + "/connect";
        sendToArena(url);

        selectPanel("musica");
        
    }

    const sendToArena = async ( url ) => {
        try {
            const responseReset = await axios.post(url, "false");
            const response = await axios.post(url, "true");
            console.log('Response:', response.data);
            console.log('Response:', responseReset.data);
        } catch (error) {
            console.error('Error from axios:', error); 
            /*if ( error.code === "ERR_NETWORK" ) {
                alert("No se puede encontrar el servidor de Arena");
            }*/
        }
    }

    return (
        <div className="Selector">
            <div className="head">
                <div className="head-element head-seleccion">
                    <img src={fondoSelect} alt="" className='cuadro-seleccion' /*style={{position:"absolute", width:"21%", left:"5%", top:"13vh"}}*//>
                    <div className="image-container">
                        <img src={musica?.imagen} alt=""  />
                        <img src={arco?.imagen} alt=""  className="img-arco"/>
                        <img src={lienzo?.imagen} alt="" className="img-lienzo"/>
                        {/* <img src={texto?.imagen} alt="" className="image-texto"  /> */}
                    </div>
                </div>
                <div className="head-element">
                    <img src={titulo} alt="Diseña tu carta" className="img-titulo" />
                </div>
                <div className="head-element head-logo-tl">
                    {/* <button onClick={reset} className="btn-enviar"> */}
                       <img src={carta} alt="Nueva carta" className="img-titlo-tl"/>
                    {/* </button> */}
                </div>

                <img src={liston} width={"25%"} alt="FotoListon" className="img-liston" style={{position:"absolute", right:0}}/>

            </div>
            <div className="icons">
                <div className="icon-space"></div>
                <ToolBar switchPanel={selectPanel} />
                <div className="icon-space"></div>
            </div>
            <div className="body">
                { panel==="musica"?  <PanelMusica onclicElement={selectElement} /> : null }
            </div>
            <div className="footer" />
        </div>
    );
}

export default Selector;
