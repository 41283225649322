
// cartas
import tarjeta01 from "../assets/Miniaturas_Tarjetas/Tarjeta01.png"
import tarjeta02 from "../assets/Miniaturas_Tarjetas/Tarjeta02.png"
import tarjeta03 from "../assets/Miniaturas_Tarjetas/Tarjeta03.png"
import tarjeta04 from "../assets/Miniaturas_Tarjetas/Tarjeta04.png"
import tarjeta05 from "../assets/Miniaturas_Tarjetas/Tarjeta05.png"
import tarjeta06 from "../assets/Miniaturas_Tarjetas/Tarjeta06.png"
import tarjeta07 from "../assets/Miniaturas_Tarjetas/Tarjeta07.png"
import tarjeta08 from "../assets/Miniaturas_Tarjetas/Tarjeta8.png"
import tarjeta09 from "../assets/Miniaturas_Tarjetas/Tarjeta9.png"
import tarjeta10 from "../assets/Miniaturas_Tarjetas/Tarjeta10.png"
import tarjeta11 from "../assets/Miniaturas_Tarjetas/Tarjeta11.png"
import tarjeta12 from "../assets/Miniaturas_Tarjetas/Tarjeta12.png"
import tarjeta13 from "../assets/Miniaturas_Tarjetas/Tarjeta13.png"
import tarjeta14 from "../assets/Miniaturas_Tarjetas/Tarjeta14.png"
import tarjeta15 from "../assets/Miniaturas_Tarjetas/Tarjeta15.png"




const tarjetas = [
    { imagen: tarjeta01, layer: 2, clip: 1, texto: "Lienzos 1" },
    { imagen: tarjeta02, layer: 2, clip: 2, texto: "Lienzos 1" },
    { imagen: tarjeta03, layer: 2, clip: 3, texto: "Lienzos 1" },
    { imagen: tarjeta04, layer: 2, clip: 4, texto: "Lienzos 1" },
    { imagen: tarjeta05, layer: 2, clip: 5, texto: "Lienzos 1" },
    { imagen: tarjeta06, layer: 2, clip: 6, texto: "Lienzos 1" },
    { imagen: tarjeta07,layer: 2, clip: 7, texto: "Lienzos 1" },
    { imagen: tarjeta08,layer: 2, clip: 8, texto: "Lienzos 1" },
    { imagen: tarjeta09, layer: 2, clip: 9, texto: "Lienzos 1" },
    { imagen: tarjeta10, layer: 2, clip: 10, texto: "Lienzos 1" },
    { imagen: tarjeta11, layer: 2, clip: 11, texto: "Lienzos 1" },
    { imagen: tarjeta12, layer: 2, clip: 12, texto: "Lienzos 1" },
    { imagen: tarjeta13, layer: 2, clip: 13, texto: "Lienzos 1" },
    { imagen: tarjeta14, layer: 2, clip: 14, texto: "Lienzos 1" },
    { imagen: tarjeta15, layer: 2, clip: 15, texto: "Lienzos 1" },
];


export {tarjetas};