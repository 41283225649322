import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";

import "../user_2.scss";

import selMusica from "../../../assets/img_aniversario/Inicio/PL_1N.png";
import selArco from "../../../assets/img_aniversario/Inicio/PL_2N.png";
import selFondo from "../../../assets/img_aniversario/Inicio/PL_3N.png";
import selTexto from "../../../assets/img_Dia_delas_Madres/Inicio/PL_4N.png";
import selFoto from "../../../assets/img_aniversario/Inicio/PL_5N.png";

import selectedMusica from "../../../assets/img_aniversario/Inicio/PL2_1.png"
import selectedArco from "../../../assets/img_aniversario/Inicio/PL2_2.png"
import selectedFondo from "../../../assets/img_aniversario/Inicio/PL2_3.png"
import selectedTexto from "../../../assets/img_Dia_delas_Madres/Inicio/PL2_4.png"
import selectedFoto from "../../../assets/img_aniversario/Inicio/PL2_5.png"

const ToolBar = ({ switchPanel }) => {

    const [onPanel, setonPanel] = useState("musica")

    const seleccionaPanel = (panel) => {
        setonPanel(panel)
        switchPanel(panel);
    }

    return(
        <div className="icons-container">
            <div className="icon-div icon-musica">
                <IconButton onClick={ () => seleccionaPanel("musica")} sx={{width:"100%",}} disableRipple>
                    <img src={onPanel === "musica" ? selectedMusica : selMusica} alt="" style={{width: "70%"}} />
                </IconButton>
            </div>
            <div className="icon-div icon-arco">
                <IconButton onClick={ () => seleccionaPanel("arco")} sx={{width:"100%", marginTop:"-34%"}} disableRipple>
                    <img src={onPanel === "arco" ? selectedArco : selArco}
                    alt="" style={{width: "70%"}} />
                </IconButton>
            </div>
            <div className="icon-div icon-fondo">
                <IconButton onClick={ () => seleccionaPanel("lienzo")} sx={{width:"100%"}} disableRipple>
                    <img src={onPanel === "lienzo" ? selectedFondo : selFondo}
                    alt="" style={{width: "70%"}} />
                </IconButton>
            </div>
            {/* <div className="icon-div icon-texto">
                <IconButton onClick={ () => seleccionaPanel("texto")} sx={{width:"100%"}} disableRipple>
                    <img src={onPanel === "texto" ? selectedTexto : selTexto}
                    alt="" style={{width: "70%"}} />
                </IconButton>
            </div> */}
            {/* <div className="icon-div icon-foto">
                <IconButton onClick={ () => seleccionaPanel("foto")} sx={{width:"100%"}} disableRipple>
                    <img src={onPanel === "foto" ? selectedFoto : selFoto}
                    alt="" style={{width: "70%"}} />
                </IconButton>
            </div> */}
        </div>
    );

}

export default ToolBar;